import {
  CheckCircleOutlined,
  LeftOutlined,
  PlusOutlined,
  SyncOutlined
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Slider,
  Space,
  Spin,
  Switch,
  Tabs,
  Typography,
  Upload
} from 'antd';
import Axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Prompt,
  Redirect,
  useHistory,
  useParams,
  withRouter
} from 'react-router-dom';
import { AuthContext } from '../../../../Auth';
import api from '../../../../common/api';
import {
  DESIGN_CENTRE,
  LEGACY_DESIGN_EXPERIENCE,
  NOTE_MESSAGE
} from '../../../../common/constant';
import { catchErrorInSentry } from '../../../../common/utils';
import { firebase } from '../../../../firebase';
import './addDesign.css';
import CardUsersDetails from './CardUsersDetails';
import DesignCenter from './DesignCenter';
import QrPosition from './QrPosition';
import QrSettings from './QrSettings';
import ShowTemplateModal from './showTemplateModal';
import CardColors from './CardColors';
import { CARD_COLORS } from '../../../../common/constant';
import ShowCardPreview from './ShowCardPreview';

const bucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
const path = `https://storage.googleapis.com/${bucket}/assets/images`;

const axios = require('axios');
const { TabPane } = Tabs;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 }
};
const layout2 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 }
};
const rotationMarks = {
  0: '0°',
  90: '90°',
  180: '180°',
  270: '270°'
};
let frontHtml, backHtml;
function AddDesign() {
  const { id } = useParams();
  const history = useHistory();
  const [set, setSet] = useState({});
  const [activeUserTab, setActiveUserTab] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [qrWidth, setQrWidth] = useState(145);
  const [qrHeight, setQrHeight] = useState(145);
  const [qrX, setQrX] = useState(130);
  const [qrY, setQrY] = useState(55);
  const [qrRotation, setQrRotation] = useState(0);
  const [roundedCorner, setRoundedCorner] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [cardDesign, setCardDesign] = useState({});
  const chatRef = firebase.firestore().collection('chats');
  const { data } = useContext(AuthContext);
  const [qrSide, setQrSide] = useState('BACK');
  const [hasSameFrontSide, setHasSameFrontSide] = useState(false);
  const [hasSameBackSide, setHasSameBackSide] = useState(false);
  const [note, setNote] = useState(NOTE_MESSAGE);
  const [uploadedDesigns, setUploadedDesigns] = useState(0);
  const [showQr, setShowQr] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [retryIndex, setRetryIndex] = useState(0);
  const [showRetryBtn, setShowRetryBtn] = useState(false);
  const [showQrPositionSetting, setShowQrPositionSetting] = useState(false);
  const [base64Str, setBase64Str] = useState('');
  const [qrSettingLoader, setQrSettingLoader] = useState(false);
  const [showValidateNote, setShowValidateNote] = useState(false);
  const [isPreviousConfig, setIsPreviousConfig] = useState(false);
  const [designExperience, setDesignExperience] = useState(DESIGN_CENTRE);
  const [template, setTemplate] = useState();
  const [templateLoading, setTemplateLoading] = useState(true);
  const [cardData, setCardData] = useState([]);
  const [newField, setNewField] = useState('');
  const [hideField, setHideField] = useState(true);
  const [currentTab, setCurrentTab] = useState('front');
  const [activeTab, setActiveTab] = useState('1');
  const [front, setFront] = useState({});
  const [back, setBack] = useState({});
  const [backgroundColorFront, setBackgroundColorFront] = useState('#ffffff');
  const [backgroundColorBack, setBackgroundColorBack] = useState('#ffffff');
  const [fontData, setFontData] = useState();
  const [sameBackgroundColor, setSameBackgroundColor] = useState(true);
  const [frontCardImage, setFrontCardImage] = useState();
  const [backCardImage, setBackCardImage] = useState();
  const [fontFace, setFontFace] = useState('');
  const [frontImageUploading, setFrontImageUploading] = useState(false);
  const [backgroundImageUploading, setBackgroundImageUploading] =
    useState(false);
  const [orgId, setOrgId] = useState();
  const [profileId, setProfileId] = useState();
  const [frontNode, setFrontNode] = useState();
  const [backNode, setBackNode] = useState();
  const [editingTemplate, setEditingTemplate] = useState(false);
  const [qrConfig, setQrConfig] = useState({
    qrType: 'center-logo',
    qrPadding: 100,
    qrPixelColor: '#000',
    qrBackgroundColor: '#ffffff',
    qrRadius: false,
    qrLogoUrl: '',
    showText: true,
    qrRoundedCorner: true,
    qrTransparent: true
  });
  const [savingTemplate, setSavingTemplate] = useState(false);
  const [isSaveQrClicked, setIsSaveQrClicked] = useState(false);
  const [isTabSwitched, setIsTabSwitched] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [fetchingPreviousDesign, setFetchingPreviousDesign] = useState(false);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [overLapUserIndexes, setOverLapUserIndexes] = useState([]);
  const [selectedColor, setSelectedColor] = useState();
  const [frontDownloadURL, setFrontDownloadUrl] = useState();
  const [backDownloadURL, setBackDownloadUrl] = useState();
  const [cardMaterial, setCardMaterial] = useState();
  const isFirstRender = useRef(true);

  let csvData = [];
  let allFontFace = '';

  const getOrgId = async (ownerEmail) => {
    try {
      if (ownerEmail) {
        const profileData = await getProfileData(ownerEmail);
        if (profileData?.orgId) {
          setOrgId(profileData?.orgId);
        } else {
          setProfileId(profileData?.userId);
        }
        if (profileData) {
          setTemplateLoading(false);
        }
      }
    } catch (error) {
      catchErrorInSentry(error);
      setTemplateLoading(false);
      console.log(error);
    }
  };
  const getProfileData = async (ownerEmail) => {
    const profileData = await api?.get(
      `${process.env.REACT_APP_API_GTW_URL}/v1/profiles/email/${ownerEmail}`
    );
    return profileData?.data?.data;
  };

  function getSet() {
    setLoading(true);
    firebase
      .firestore()
      .collection('sets')
      .where('setUniqueId', '==', id)
      .onSnapshot((querySnapshot) => {
        const updatedCardUsers = []; 
        const cardDesign = {};
        querySnapshot.docs[0].data().cardUsers.map((user, index) => {
          const employeeUniqueId = `${
              querySnapshot?.docs[0]?.data()?.setUniqueId
            }_${index}`;
          if (!user.employeeUniqueId) {
            user.employeeUniqueId = employeeUniqueId;
          }
          updatedCardUsers.push({
            ...user,
            employeeUniqueId,
          });
          cardDesign[employeeUniqueId] = {
            cardUser: user
          }
        });
        setSet({
          ...querySnapshot?.docs[0]?.data(),
          cardUsers: updatedCardUsers
        });
        setCardDesign(cardDesign);
        const setData = querySnapshot?.docs[0]?.data();
        const cardType = setData.cardType;
        setCardMaterial(cardType);
        if (
          (isColorCardType(cardType)) && 
           isFirstRender.current &&
           !setData.currentDesignId
          ) {
            setSelectedColor(updatedCardUsers[0].cardColor);
            isFirstRender.current = false;
        }
      });
      setLoading(false);
  }

  useEffect(() => {
    const obj = {};
    if (set?.cardUsers && set?.owner) {
      set.cardUsers.forEach((user) => {
        obj[user?.employeeUniqueId] = {
          uploadInProgress: false,
          docId: null,
          iFrameUrl: null
        };
      });
      if (set?.owner) {
        getOrgId(set?.owner).then(() => {
          setTemplateLoading(false);
          checkValidate();
        });
      }
      if (set?.currentDesignId && !template?.templateId) {
        getLastDesign(set?.setUniqueId, set?.currentDesignId);
        checkValidate();
        validate();
      }
    }
  }, [set]);

  useEffect(() => {
    const fetchImages = async () => {
      const cardDesignArray = Object.values(cardDesign);
      const concurrencyLimit = 3;
      let activeTasks = 0;
      let queue = [];
      let firstUploadTriggered = false;
  
      for (const [index, card] of cardDesignArray.entries()) {
        for (const side of ['front', 'back']) {
          queue.push({ card, side, index });
        }
      }
  
      const processNext = async () => {
        if (queue.length === 0 && activeTasks === 0) {
          setLoading(false);
          return;
        }
  
        if (activeTasks < concurrencyLimit && queue.length > 0) {
          const { card, side, index } = queue.shift();
          activeTasks++;
  
          try {
            const url = side === 'front' ? card.frontUploadImage : card.backUploadImage;
            let cardImage;
            if (url) {
              if (!firstUploadTriggered) {
                message.loading({
                  content: 'Uploading, please wait for a while...',
                  key: 'uploading',
                  duration: 30
                });
                firstUploadTriggered = true;
              }
              cardImage = await createColoredImage(selectedColor, url, side, set?.orderUniqueId, index);
            } else {
              cardImage = getCardImage(selectedColor);
            }
            card[`${side}BGImageUrl`] = cardImage;
            const empId = `${set?.setUniqueId}_${index}`;
            setCardDesign((prev) => ({
              ...prev,
              [empId]: {
                ...prev[empId],
                [`${side}BGImageUrl`]: cardImage,
              },
            }));
            updateCardImage(side, cardImage);
          } catch (error) {
            console.error(`Error processing ${side} for index ${index}:`, error);
          }
  
          activeTasks--;
          processNext(); // Start next task
        }
      };
  
      if (selectedColor) {
        setLoading(true);
        for (let i = 0; i < concurrencyLimit; i++) {
          processNext();
        }
      }
    };
  
    fetchImages();
  }, [selectedColor]);
  
  const getCardImage = (color) => {
    if (isColorCardType(cardMaterial)) {
      const imageMap = {
        [CARD_COLORS.BIRCH_LIGHT]: `${path}/bg_birch.png`,
        [CARD_COLORS.SAPELE_DARK]: `${path}/bg_sapele.png`,
        [CARD_COLORS.SILVER]: `${path}/bg_silver.png`,
        [CARD_COLORS.GOLD]: `${path}/bg_gold.png`,
        [CARD_COLORS.BLACK]: `${path}/bg_black.png`,
        [CARD_COLORS.WHITE]: `${path}/bg_white.png`
      };
      return imageMap[color] || '';
    }
  };

  const handleUpload = async (file, side, user, hasSameDesign) => {
    const fileImageProperty = side === 'FRONT' ? 'frontUploadImage' : 'backUploadImage';
    
    setUploadInProgress(true);
    message.loading({ content: 'Uploading, please wait...', key: 'uploading', duration: 10 });
  
    if (file?.type !== 'image/png') {
      message.error({ content: 'You can only upload PNG files!', key: 'uploading', duration: 3 });
      setUploadInProgress(false);
      return;
    }
  
    try {
      const imageData = await readFileAsDataURL(file);
      const { width, height } = await getImageDimensions(imageData);
  
      if (width !== 2022 || height !== 1275) {
        message.error({ content: 'Image must be 2022px x 1275px', key: 'uploading', duration: 3 });
        setUploadInProgress(false);
        return;
      }
  
      const downloadURL = await uploadFileToStorage(file, side);
      if (!downloadURL) throw new Error('Failed to upload image');
  
      side === 'FRONT' ? setFrontDownloadUrl(downloadURL) : setBackDownloadUrl(downloadURL);
  
      const cardColor = selectedColor || set?.cardUsers[0].cardColor || '';
      const cardImage = await createColoredImage(cardColor, downloadURL, side, user?.employeeUniqueId, activeUserTab);

      const empId = `${set?.setUniqueId}_${activeUserTab}`;
      const { bgImageProperty, property, uploadProperty } = getSideProperties(side);
  
      updateCardDesign(user, property, downloadURL, bgImageProperty, fileImageProperty, cardImage, hasSameDesign);
      updateCardImage(side, cardImage);
      setCardDesign((prev) => ({
        ...prev,
        [empId]: {
          ...(prev[empId] || {}),
          [property]: downloadURL,
          [bgImageProperty]: cardImage,
          [uploadProperty]: downloadURL
        },
      }));
      updateCardDesign(user, property, downloadURL, bgImageProperty, fileImageProperty, cardImage, hasSameDesign);
      setUploadInProgress(false);
      message.success({ content: 'Uploaded successfully!', key: 'uploading', duration: 3 });
    } catch (error) {
      console.error('Upload error:', error);
      message.error({ content: 'Error occurred while uploading. Please try again!', key: 'uploading', duration: 3 });
      setUploadInProgress(false);
    }
  };

  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  
  const getImageDimensions = (imageData) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve({ width: image.width, height: image.height });
      image.onerror = reject;
      image.src = imageData;
    });
  };
  
  const uploadFileToStorage = (file, side) => {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef.child(`images/${set?.setUniqueId}-${side}-${file?.uid}`).put(file);
  
      uploadTask.on(
        'state_changed',
        null,
        (error) => reject(error),
        async () => {
          try {
            const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
            resolve(downloadURL);
          } catch (error) {
            reject(error);
          }
        }
      );
    });
  };
  
  const updateCardDesign = (user, property, downloadURL, bgImageProperty, fileImageProperty, coloredImage, hasSameDesign) => {
    if (hasSameDesign) {
      let temp = {};
      set.cardUsers.forEach((user) => {
        delete user?.cardDesignTemplateId;
        temp[user?.employeeUniqueId] = {
          ...cardDesign[user?.employeeUniqueId],
          [property]: downloadURL,
          [bgImageProperty]: coloredImage,
          [fileImageProperty]: downloadURL,
          cardUser: user
        };
      });
      setCardDesign({ ...cardDesign, ...temp });
    } else {
      delete user?.cardDesignTemplateId;
    }
  };

  const getQrData = async () => {
    try {
      setQrSettingLoader(true);
      const token = await firebase.auth().currentUser.getIdToken(true);
      const generateQrCode = await Axios.post(
        `${process?.env?.REACT_APP_SERVER_URL_V2}/design-template/generate-qr`,
        {
          link: 'https://www.mobilocard.com',
          pixelColor: qrConfig?.qrPixelColor,
          ...(qrConfig?.qrType !== 'background'
            ? { backgroundColor: qrConfig?.qrBackgroundColor }
            : {}),
          qrPadding: qrConfig?.qrPadding,
          logoUrl: qrConfig?.qrLogoUrl,
          qrType: qrConfig?.qrType,
          radius: qrConfig?.qrRadius, // radius is true if qr code boxes are rounded
          showText: qrConfig?.showText,
          qrRoundedCorner: qrConfig?.qrRoundedCorner,
          qrShowLogo: qrConfig?.qrShowLogo,
          qrTransparent: qrConfig?.qrTransparent
        },
        { headers: { authorization: `Bearer ${token}` } }
      );
      setBase64Str(generateQrCode?.data?.data);
      setQrSettingLoader(false);
    } catch (err) {
      catchErrorInSentry(err?.response?.data?.message);
      setQrSettingLoader(false);
      console.log(err?.message);
    }
  };

  useEffect(() => {
    if (qrConfig?.qrType) {
      getQrData();
    }
  }, [qrConfig]);
  const getLastDesingOfOrder = async () => {
    setFetchingPreviousDesign(true);
    const token = await firebase.auth().currentUser.getIdToken(true);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/v2/card-design-template/get?templateId=${template?.templateId}&orderUniqueId=${set?.orderUniqueId}&userIndex=${activeUserTab}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    setFetchingPreviousDesign(false);
    if (response?.data) {
      setFrontCardImage(response?.data?.data?.frontCardImage);
      setBackCardImage(response?.data?.data?.backCardImage);
      setFontData(response?.data?.data?.fontsData);
      setNewField(response?.data?.data?.front?.customField?.value);
      setBackgroundColorFront(response?.data?.data?.backgroundColorFront);
      setBackgroundColorBack(response?.data?.data?.backgroundColorBack);
      setFront(response?.data?.data?.front);
      setBack(response?.data?.data?.back);
      setFrontNode(response?.data?.data?.frontHTMLData);
      setBackNode(response?.data?.data?.backHTMLData);
      if (response?.data.data?.cardColor) {
        setSelectedColor(response?.data.data?.cardColor);
      }
      response.data.data.fontsData.map((font) => {
        const fontFace = new FontFace(font?.name, `url(${font?.link})`);
        addFontFace(font?.name, font?.link);
        document.fonts.add(fontFace);
        fontFace.load();
      });
    }
  };

  const isColorCardType = (cardType) => {
    if (
      cardType === 'Mobilo Metal (promo)' ||
      cardType === 'Mobilo Wood' ||
      cardType === 'Mobilo Metal'  ||
      cardType === 'Mobilo Hybrid Metal'
    ){
      return true;
    }
    return false;
  }

  const handleDesignForApproval = async () => {
    await api
      .post(`${process?.env?.REACT_APP_SERVER_URL_V2}/orders/approve-design`, {
        orderUniqueId: set?.orderUniqueId,
        setUniqueId: set?.setUniqueId
      })
      .then(() => {
        setRedirect(true);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendWithRetry = async (apiCall, params, attempt = 1, retryLimit = 3) => {
    try {
      return await apiCall(params);
    } catch (err) {
      console.error(`Attempt ${attempt} failed:`, err);
  
      if (attempt < retryLimit) {
        const delay = Math.pow(2, attempt) * 1000;
        await new Promise((resolve) => setTimeout(resolve, delay));
        return sendWithRetry(apiCall, params, attempt + 1, retryLimit);
      }
  
      message.error('Something went wrong while processing your request.');
      return null;
    }
  };

  const generateQrImages = async ({ batch, token, i }) => {
    return axios.post(
      `${process.env.REACT_APP_SERVER_URL_V2}/design-template/preview/qr`,
      {
        images: batch,
        qrHeight: qrHeight * 5,
        qrWidth: qrWidth * 5,
        qrX: qrX * 5,
        qrY: qrY * 5,
        ...(base64Str && { qrLink: base64Str }),
        ...(qrRotation && { qrRotation }),
      },
      { headers: { authorization: `Bearer ${token}` } }
    );
  }
  async function handleSubmit() {
    try {
      setLoading(true);
      setShowRetryBtn(false);
      
      const token = await firebase.auth().currentUser.getIdToken(true);
      const cardDesignArray = Object.values(cardDesign);
  
      const imagesToCompose = cardDesignArray.map((card) => {
        card.frontImageUrl = card.frontImageUrl || getCardImage('White');
        card.backImageUrl = card.backImageUrl || getCardImage('White');
        return qrSide === 'FRONT' ? card.frontImageUrl : card.backImageUrl;
      });
  
      let imagesGenerate = new Array(imagesToCompose.length);
  
      if (showQr) {
        const batchSize = 5;
        const batchRequests = [];
  
        for (let i = 0; i < imagesToCompose.length; i += batchSize) {
          const batch = imagesToCompose.slice(i, i + batchSize);
          batchRequests.push(
            sendWithRetry(generateQrImages, { batch, token, i })
          );
        }
  
        const results = await Promise.all(batchRequests);
        results.forEach((response, i) => {
          if (response) {
            response.data.data.forEach((image, index) => {
              imagesGenerate[i * 5 + index] = image;
            });
          }
        });
      }
  
      let successfulUploads = 0;
      for (let i = 0; i < cardDesignArray.length; i++) {
        const design = cardDesignArray[i];
        try {
          design.qrReference = showQr ? imagesGenerate[i] || '' : '';
          design.qrBGReference = showQr ? (qrSide === 'FRONT' ? design.frontBGImageUrl : design.backBGImageUrl) : '';
  
          design.frontBGImageUrl = design.frontBGWithoutQrImageUrl || design.frontBGImageUrl;
          design.backBGImageUrl = design.backBGWithoutQrImageUrl || design.backBGImageUrl;
          design.cardUser.cardColor = selectedColor || '';
  
          setUploadedDesigns(i + 1);
          successfulUploads++;
        } catch (err) {
          catchErrorInSentry(err?.response?.data?.message);
          setUploadedDesigns(i);
          setShowRetryBtn(true);
          setRetryIndex(i);
          setLoading(false);
          return;
        }
      }
  
      if (successfulUploads === cardDesignArray.length) {
        const ref = chatRef.doc(set?.setUniqueId).collection('messages').doc();
        await ref.set({
          docId: ref.id,
          action: 'NO_ACTION_TAKEN',
          actionRequired: true,
          message: note,
          orderUniqueId: set?.orderUniqueId,
          senderId: data?.userId,
          senderName: data?.fullName,
          setUniqueId: set?.setUniqueId,
          createdAt: new Date(),
          designSet: {
            qrDesignConfig: qrConfig,
            cardWidth: 2022,
            cardHeight: 1275,
            qrWidth: qrWidth * 5,
            qrHeight: qrHeight * 5,
            qrX: qrX * 5,
            qrY: qrY * 5,
            cardDesign: cardDesignArray,
            qrSide: showQr ? qrSide : 'NA',
            showQR: showQr,
            qrRotation,
            user: set?.owner,
            cardType: set?.cardType,
          },
        });
  
        await Promise.all([
          firebase.firestore()
            .collection('sets')
            .where('setUniqueId', '==', set?.setUniqueId)
            .get()
            .then((querySnapshot) =>
              querySnapshot.docs[0]?.ref.update({
                isAlreadyDesigned: true,
                status: 'NEED_DESIGN_APPROVAL',
                currentDesignId: ref.id,
                updatedAt: new Date(),
              })
            ),
  
          firebase.firestore()
            .collection('orders')
            .where('orderUniqueId', '==', set?.orderUniqueId)
            .get()
            .then((querySnapshot) =>
              querySnapshot.docs[0]?.ref.update({
                reviewRequired: true,
                updatedAt: new Date(),
                status: 'NEED_DESIGN_APPROVAL',
              })
            ),
        ]);
  
        if (!set?.isPreApproved) {
          handleDesignForApproval();
        } else {
          message.success('This order is pre-approved!');
          setRedirect(true);
          setLoading(false);
        }
  
        setCardDesign({});
        message.success('Design Uploaded successfully');
      }
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setShowRetryBtn(true);
      setLoading(false);
    }
  }
  
  async function getLastDesign(setUniqueId, id) {
    const designSet = await firebase
      .firestore()
      .collection('chats')
      .doc(setUniqueId)
      .collection('messages')
      .doc(id)
      .get();
    const chatData = designSet?.data();
    const design = chatData?.designSet;
    setQrWidth(design?.qrWidth / 5);
    setQrHeight(design?.qrHeight / 5);
    setQrX(design?.qrX / 5);
    setQrY(design?.qrY / 5);
    const updatedCardDesign = design?.cardDesign.reduce((acc, cardDesign, index) => {
      const user = cardDesign.cardUser;
      const empId = user.employeeUniqueId || (set?.setUniqueId + '_' + index);
      const bgBackImageUrl = cardDesign.backBGImageUrl ||  cardDesign.backImageUrl;
      const bgFrontImageUrl = cardDesign.frontBGImageUrl || cardDesign.frontImageUrl;
      acc[empId] = {
        backBGImageUrl: bgBackImageUrl,
        frontBGImageUrl: bgFrontImageUrl,
        backImageUrl: cardDesign.backImageUrl,
        frontImageUrl: cardDesign.frontImageUrl,
        backUploadImage: cardDesign.backUploadImage || '',
        frontUploadImage: cardDesign.frontUploadImage || '',
        selectedColor: user.cardColor,
        cardUser: user,
      };
      return acc;
    }, {});
    const empId = set?.setUniqueId + '_' + activeUserTab;
    setCardDesign(updatedCardDesign);
    setFrontCardImage(updatedCardDesign[empId]?.frontBGImageUrl);
    setBackCardImage(updatedCardDesign[empId]?.backBGImageUrl);
    setFrontDownloadUrl(updatedCardDesign[empId]?.frontUploadImage);
    setBackDownloadUrl(updatedCardDesign[empId]?.backUploadImage);
    // setSelectedColor(updatedCardDesign[empId]?.selectedColor);
    if (chatData.templateId && set.isAlreadyDesigned) {
      setTemplate({templateId: chatData.templateId});
    }
  }

  useEffect(() => {
    getSet();
  }, []);
  const checkValidate = () => {
    if (set && set?.cardUsers) {
      for (const user of set?.cardUsers) {
        if (user?.firstName?.length > 0 && user?.email?.length > 0) {
          setDisableSubmit(false);
          setShowValidateNote(false);
        } else {
          setShowValidateNote(true);
          setDisableSubmit(true);
          break;
        }
      }
    }
  };

  useEffect(() => {
    if ((orgId || profileId) && !template?.templateId) {
      getTemplateData();
    }
    if (set?.isAlreadyDesigned && template?.templateId) {
      setIsEditModeOn(true);
    }
    if (set?.isAlreadyDesigned && cardDesign) {
      const empId = set?.setUniqueId + '_' + activeUserTab;
      if (cardDesign[empId]) {
        setFrontCardImage(cardDesign[empId]?.frontBGImageUrl);
        setBackCardImage(cardDesign[empId]?.backBGImageUrl);
      }
    }
  }, [orgId, profileId, activeUserTab, template?.templateId]);
  const getOverLapUserIndex = async () => {
    try {
      const response = await firebase
        .firestore()
        .collection('orders')
        .where('orderUniqueId', '==', set?.orderUniqueId)
        .get();
      response.docs.forEach((doc) => {
        setOverLapUserIndexes(doc?.data()?.overLapUserIndexes);
      });
    } catch {}
  };

  useEffect(() => {
    if (isEditModeOn) {
      getOverLapUserIndex();
    }
  }, [isEditModeOn]);

  const validate = () => {
    const length = set?.cardUsers ? set?.cardUsers?.length : null;
    let correctSet = 0;
    Object.values(cardDesign).forEach((card, index) => {
      if (
        ('frontImageUrl' in card || 'frontBGImageUrl' in card)  &&
        ('backImageUrl' in card || 'backBGImageUrl' in card) &&
        note?.length > 0
      ) {
        correctSet += 1;
      }
    });
    return correctSet !== length;
  };

  useEffect(() => {
    generateHtml(currentTab);
  }, [
    front,
    back,
    backgroundColorBack,
    backgroundColorFront,
    currentTab,
    frontCardImage,
    backCardImage
  ]);

  if (redirect) {
    return <Redirect to="/design/inprogress" />;
  }
  const options = [
    { label: 'Design Center', value: DESIGN_CENTRE },
    { label: 'Legacy Design Experience', value: LEGACY_DESIGN_EXPERIENCE }
  ];
  const handleDesignExperience = (e) => {
    setDesignExperience(e?.target?.value);
  };

  const getTemplateData = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      if (token) {
        const templateData = await axios({
          method: 'get',
          url: `${process?.env?.REACT_APP_SERVER_URL}/v2/design-template/get?${
            orgId ? `orgId=${orgId}` : `userId=${profileId}`
          }`,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
          }
        });
        setTemplate(templateData?.data?.data);
        set.cardUsers.map((user) =>
          csvData.push({ email: user?.email, value: user?.email })
        );
        setTemplateLoading(false);
      }
    } catch (error) {
      catchErrorInSentry(error?.response?.data?.message);
      setTemplateLoading(false);
    }
  };
  //wait functional is written intentionally.(after forcefully switching the tab, before hitting api, cards' htmlNode is formed during the wait period)
  const wait = (time = 5000) =>
    new Promise((resolve) => setTimeout(resolve, time));
  const editDesign = async () => {
    try {
      setEditingTemplate(true);
      const accessToken = await firebase.auth().currentUser.getIdToken(true);
      let dataToSend = {
        front: front,
        back: back,
        backgroundColorFront: backgroundColorFront,
        backgroundColorBack: backgroundColorBack,
        frontCardImage: frontCardImage,
        backCardImage: backCardImage,
        orgId: orgId,
        userId: profileId,
        showQR: showQr,
        base64Str: base64Str,
        fontsData: fontData || [],
        qrConfig,
        qrWidth,
        qrHeight,
        qrX,
        qrY,
        qrRotation,
        qrSide,
        cardColor: selectedColor
      };
      if (accessToken) {
        const data = await axios({
          method: 'post',
          url: `${
            process?.env?.REACT_APP_SERVER_URL
          }/v2/card-design-template/save?${
            orgId ? `orgId=${orgId}` : `userId=${profileId}`
          }&orderUniqueId=${set?.orderUniqueId}&userIndex=${activeUserTab}`,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          },
          data: dataToSend
        });
        if (data) {
          await api({
            method: 'post',
            url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/save-html?templateId=${data?.data?.data}`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              front: frontNode,
              back: backNode,
              forOrderLevel: true
            }
          })
            .then(async () => {
              setEditingTemplate(false);
            })
            .catch(() => {
              setEditingTemplate(false);
            });
        }
      }
    } catch (error) {
      message.error('something went wrong');
      console.log(error);
    }
  };
  const handleBulkGeneration = async () => {
    try {
      const bulkGeneratioResponseData = await api.get(
        `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/create-preview?orderUniqueId=${set?.orderUniqueId}&designTemplateId=${template?.templateId}`,
        {
          headers: {
            secretkey: process.env.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      if (bulkGeneratioResponseData) {
        message.success(bulkGeneratioResponseData?.data?.message);
        setRedirect(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const saveTemplate = async (frontNode, backNode) => {
    try {
      setSavingTemplate(true);
      let imageData;
      const accessToken = await firebase.auth().currentUser.getIdToken(true);
      if (frontNode && backNode) {
        imageData = await api({
          method: 'post',
          url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/get-png?orderUniqueId=${set?.orderUniqueId}`,
          headers: {
            'Content-Type': 'application/json',
            secretKey: process.env.REACT_APP_PDF_FIX_SECRET
          },
          data: {
            front: frontNode,
            back: backNode,
            fontsData: fontData || []
          }
        });
      } else {
        message.warn('Something went wrong!');
        setSavingTemplate(false);
      }
      let dataToSend = {
        front: front,
        back: back,
        backgroundColorFront: backgroundColorFront,
        backgroundColorBack: backgroundColorBack,
        frontCardImage: frontCardImage,
        backCardImage: backCardImage,
        frontUploadImage: frontDownloadURL,
        backUploadImage: backDownloadURL,
        orgId: orgId,
        userId: profileId,
        frontImage: imageData?.data?.data[0]?.url,
        backImage: imageData?.data?.data[1]?.url,
        showQR: showQr,
        base64Str: base64Str,
        fontsData: fontData || [],
        qrConfig,
        qrWidth,
        qrHeight,
        qrX,
        qrY,
        qrRotation,
        qrSide,
        cardColor: selectedColor
      };
      if (accessToken && imageData) {
        const data = await axios({
          method: 'post',
          url: `${process?.env?.REACT_APP_SERVER_URL}/v2/design-template/save`,
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${accessToken}`
          },
          data: dataToSend
        });
        setSavingTemplate(false);
        message.success(data?.data?.message);
        if (data) {
          await api({
            method: 'post',
            url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/save-html?templateId=${data?.data?.data}`,
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${accessToken}`
            },
            data: {
              front: frontNode,
              back: backNode
            }
          }).then(() => {
            getTemplateData();
          });
        }
      }
    } catch (error) {
      message.error('something went wrong');
      setSavingTemplate(false);
      console.log(error);
    }
  };
  const handleFontUpload = async (fileList, formData) => {
    try {
      const token = await firebase.auth().currentUser.getIdToken(true);
      setUploadInProgress(true);
      const data = await api.post(
        `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/upload-fonts?orderUniqueId=${set?.orderUniqueId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            secretKey: process?.env?.REACT_APP_PDF_FIX_SECRET
          }
        }
      );
      setFontData(data?.data?.data);
      setUploadInProgress(false);
      data.data.data.map((font) => {
        const fontFace = new FontFace(font?.name, `url(${font?.link})`);
        addFontFace(font?.name, font?.link);
        document.fonts.add(fontFace);
        fontFace.load();
      });
    } catch (error) {
      console.log(error);
      setUploadInProgress(false);
    }
  };
  const addFontFace = (fontFamily, src) => {
    if (fontFamily && src) {
      const newFontFace = `@font-face{
        font-family:${fontFamily};
        src: url(${src})
      }`;
      allFontFace = newFontFace + allFontFace;
      setFontFace(allFontFace);
    }
  };
  const generateHtml = async (node) => {
    if (node == 'front') {
      frontHtml = document.getElementById('card-layout-front-wrapper');
      setFrontNode(
        `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${frontHtml?.innerHTML}</body></html>`
      );
    } else {
      backHtml = document.getElementById('card-layout-back-wrapper');
      setBackNode(
        `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${backHtml?.innerHTML}</body></html>`
      );
    }
  };

  const createColoredImage = async (selectedColor, downloadURL, side, orderUniqueId, index = 0) => {
    if (!(
      isColorCardType(cardMaterial)
    )) {
      return downloadURL;
    }
    return await api.post(
      `${process.env.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/create-color-image`,
      {
        selectedColor: selectedColor,
        overlayImage: downloadURL,
        side,
        orderUniqueId,
        index
      },
      {
        headers: {
          secretKey: process?.env?.REACT_APP_PDF_FIX_SECRET
        }
      }
    ).then((data) => {
      return data.data.data;
    }).catch((err) => {
      message.error('something went wrong');
      setLoading(false);
      console.log(err);
    });
  }

  const updateCardImage = (side, imageUrl) => {
    side = side.toLowerCase();
    if (side === 'front') {
      setFrontCardImage(imageUrl);
    } else {
      setBackCardImage(imageUrl);
    }
  };
  
  const getSideProperties = (side) => {
    side = side.toLowerCase();
    return side === 'front'
      ? { bgImageProperty: 'frontBGImageUrl', property: 'frontImageUrl', uploadProperty: 'frontUploadImage' }
      : { bgImageProperty: 'backBGImageUrl', property: 'backImageUrl', uploadProperty: 'backUploadImage' };
  };
  
  const onFileInput = async (e) => {
    const { files } = e?.currentTarget;
    if (files && files?.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < files?.length; i++) {
        formData.append('fonts', files[i]);
      }
      handleFontUpload(files, formData);
    }
  };
  const handleBackgroundImageUpload = async (file, side) => {
    toggleUploading(side, true);
  
    try {
      if (file?.type !== 'image/png') {
        message.error({ content: 'You can only upload PNG files!', key: 'uploading', duration: 3 });
        setUploadInProgress(false);
        return;
      }
      message.loading({ content: 'Uploading, please wait...', key: 'uploading', duration: 10 });

      const downloadURL = await uploadFileToStorage(file, side);
      const imageData = await readFileAsDataURL(file);
      const { width, height } = await getImageDimensions(imageData);

      if (width !== 2022 || height !== 1275) {
        message.error({ content: 'Image must be 2022px x 1275px', key: 'uploading', duration: 3 });
        setUploadInProgress(false);
        return;
      }
      const cardColor = selectedColor || set?.cardUsers[0].cardColor || '';
      const cardImage = await createColoredImage(cardColor, downloadURL, side, set?.orderUniqueId, activeUserTab);
      updateCardImage(side, cardImage);

      const empId = `${set?.setUniqueId}_${activeUserTab}`;
      const { bgImageProperty, property, uploadProperty } = getSideProperties(side);
  
      setCardDesign((prev) => ({
        ...prev,
        [empId]: {
          ...(prev[empId] || {}),
          [property]: downloadURL,
          [bgImageProperty]: cardImage,
          [uploadProperty]: downloadURL
        },
      })); 
      toggleUploading(side, false); 
    } catch (error) {
      console.error('Upload error:', error);
      message.error({ content: 'Error occurred while uploading. Please try again!', key: 'uploading', duration: 3 });
      setUploadInProgress(false);
    }
};
  
  const toggleUploading = (side, isUploading) => {
    if (side === 'front') {
      setFrontImageUploading(isUploading);
    } else {
      setBackgroundImageUploading(isUploading);
    }
  };

  const editQrInTemplate = async (templateId) => {
    try {
      let imageData;
      if (frontNode && backNode) {
        imageData = await api({
          method: 'post',
          url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/mat/card-design/get-png?orderUniqueId=${set?.orderUniqueId}`,
          headers: {
            'Content-Type': 'application/json',
            secretKey: process.env.REACT_APP_PDF_FIX_SECRET
          },
          data: {
            front: frontNode,
            back: backNode
          }
        });
      } else {
        message.warn('Something went wrong!');
      }
      await api?.post(
        `${process.env.REACT_APP_SERVER_URL_V2}/design-template/update`,
        {
          templateId,
          qrWidth,
          qrHeight,
          qrX,
          qrY,
          qrRotation,
          qrSide,
          showQR: showQr,
          qrConfig: {
            ...qrConfig,
            qrTransparent: qrConfig?.qrTransparent ?? false,
            qrShowLogo: qrConfig?.qrShowLogo ?? false
          },
          frontImage: imageData?.data?.data[0]?.url,
          backImage: imageData?.data?.data[1]?.url,
          cardColor: selectedColor
        }
      );
      await api({
        method: 'post',
        url: `${process?.env?.REACT_APP_PDF_DOWNLOAD_URL}/save-html?templateId=${templateId}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          front: frontNode,
          back: backNode
        }
      }).then(() => {
        getTemplateData();
      });
    } catch (error) {
      message.error('something went wrong');
      setSavingTemplate(false);
      console.log(error);
    }
  };
  return (
    <Spin spinning={loading}>
    <>
      <Prompt
        when={true}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Card
        bodyStyle={{
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.4 : 1
        }}
        title={
          <Space>
            <Button
              disabled={loading}
              onClick={() => {
                history.goBack();
              }}
              type="text"
              icon={<LeftOutlined />}
            />
            <Typography.Title style={{ margin: 0 }} level={3}>
              {id} | Add Design
            </Typography.Title>
          </Space>
        }
        extra={
          designExperience === LEGACY_DESIGN_EXPERIENCE ? (
            showRetryBtn ? (
              <Button
                loading={loading}
                icon={<SyncOutlined />}
                disabled={validate() || disableSubmit}
                type="primary"
                onClick={() => handleSubmit()}
              >
                Retry
              </Button>
            ) : (
              <Button
                loading={loading}
                disabled={validate() || disableSubmit}
                type="primary"
                onClick={() => handleSubmit()}
              >
                {loading
                  ? `Uploading (${uploadedDesigns}/${
                      Object.values(cardDesign)?.length
                    })`
                  : 'Submit'}
              </Button>
            )
          ) : null
        }
      >
        <div className="design-experience-toggle-section">
          <Radio.Group
            options={options}
            onChange={handleDesignExperience}
            value={designExperience}
            optionType="button"
          />
        </div>
        <Card
          size="small"
          title="Upload Designs (Designs must be PNG with 2022px x 1275px dimensions)"
          extra={
            set?.cardUsers?.length > 1 ? (
              <Space>
                <Space style={{ marginRight: '1rem' }}>
                  <Typography.Text>Same front design : </Typography.Text>
                  <Switch onChange={(e) => setHasSameFrontSide(e)} />
                </Space>
                <Space>
                  <Typography.Text>Same back design : </Typography.Text>
                  <Switch onChange={(e) => setHasSameBackSide(e)} />
                </Space>
              </Space>
            ) : null
          }
        >
          <Tabs
            type="card"
            onTabClick={(e) => {
              setCurrentTab('front');
              setActiveTab('1');
              setIsTabSwitched(false);
              setIsSaveQrClicked(false);
              setActiveUserTab(Number(e));
            }}
            destroyInactiveTabPane
            tabBarExtraContent={
              <Space>
                {designExperience === DESIGN_CENTRE && templateLoading ? (
                  <Spin spinning={templateLoading} />
                ) : (
                  <ShowTemplateModal
                    orgId={orgId}
                    set={set}
                    template={template}
                    setTemplate={setTemplate}
                    csvData={csvData}
                    setRedirect={setRedirect}
                  />
                )}
              </Space>
            }
          >
            {set?.cardUsers
              ? set.cardUsers.map((user, index) => {
                  return (
                    <TabPane
                      tab={
                        <Badge dot={overLapUserIndexes?.includes(index)}>
                          <Typography.Text strong>
                            {user?.firstName} {user?.lastName}
                          </Typography.Text>
                        </Badge>
                      }
                      key={index}
                    >
                      <>
                        <CardUsersDetails
                          user={user}
                          showValidateNote={showValidateNote}
                        />
                        <Row align="middle">
                          {designExperience === LEGACY_DESIGN_EXPERIENCE && (
                            <Col>
                              <Space>
                                {/* card front image upload */}
                                <Upload
                                  disabled={uploadInProgress}
                                  style={{ width: '50%' }}
                                  name="Front"
                                  listType="picture-card"
                                  showUploadList={false}
                                  action={(file) => {
                                    handleUpload(
                                      file,
                                      'FRONT',
                                      user,
                                      hasSameFrontSide
                                    );
                                  }}
                                >
                                  {
                                    cardDesign[user?.employeeUniqueId]?.frontBGImageUrl || 
                                    cardDesign[user?.employeeUniqueId]?.frontImageUrl ? (
                                    <img
                                      src={
                                        cardDesign[user?.employeeUniqueId]?.frontBGImageUrl || 
                                        cardDesign[user?.employeeUniqueId]?.frontImageUrl
                                      }
                                      alt="avatar"
                                      style={{ width: '100%' }}
                                    />
                                  ) :
                                    <div>
                                      <PlusOutlined />
                                      <div style={{ marginTop: 8 }}>
                                        Front PNG
                                      </div>
                                    </div>
                                  }
                                </Upload>
                                {/* Card back image upload */}
                                <Upload
                                  disabled={uploadInProgress}
                                  name="Back"
                                  listType="picture-card"
                                  showUploadList={false}
                                  action={(file) => {
                                    handleUpload(
                                      file,
                                      'BACK',
                                      user,
                                      hasSameBackSide
                                    );
                                  }}
                                >
                                  {cardDesign[user?.employeeUniqueId]?.backBGImageUrl ||
                                   cardDesign[user?.employeeUniqueId]?.backImageUrl ? (
                                    <img
                                      src={
                                        cardDesign[user?.employeeUniqueId]?.backBGImageUrl ||
                                        cardDesign[user?.employeeUniqueId].backImageUrl
                                      }
                                      alt="avatar"
                                      style={{ width: '100%' }}
                                    />
                                  ) :
                                  (
                                    <div>
                                      <PlusOutlined />
                                      <div style={{ marginTop: 8 }}>
                                        Back PNG
                                      </div>
                                    </div>
                                  )}
                                </Upload>
                              </Space>
                            </Col>
                          )}
                          {
                            designExperience === LEGACY_DESIGN_EXPERIENCE &&
                            isColorCardType(cardMaterial) ? (
                            <Col>
                              <Space>
                                <CardColors
                                  cardMaterial={cardMaterial}
                                  setSelectedColor={setSelectedColor}
                                  selectedColor={selectedColor}
                                />
                              </Space>
                            </Col>
                          ): null}
                        </Row>

                        {
                          designExperience === LEGACY_DESIGN_EXPERIENCE ? (
                          <div className="preview-wrapper">
                            <h4 className="preview-text">See the preview of designed card</h4>
                            <ShowCardPreview frontCardImage={frontCardImage} backCardImage={backCardImage} />
                          </div>
                        ): null}
                        <Modal
                          visible={showWarningModal}
                          onOk={() => {
                            setActiveTab('2');
                            setIsTabSwitched(false);
                            setIsSaveQrClicked(false);
                            setShowWarningModal(false);
                          }}
                          onCancel={async () => {
                            setShowWarningModal(false);
                            if (currentTab) {
                              setCurrentTab(
                                currentTab === 'front' ? 'back' : 'front'
                              );
                            }
                            await wait(2000);

                            if (frontNode && backNode) {
                              saveTemplate(frontNode, backNode);
                            }
                          }}
                        >
                          To save your current changes with QR, Kindly configure
                          QR again
                        </Modal>
                        {designExperience === DESIGN_CENTRE && (
                          <Tabs
                            defaultActiveKey="1"
                            onTabClick={async (key) => {
                              if (isEditModeOn && currentTab === 'front') {
                                setCurrentTab('back');
                                await wait(500);
                                setActiveTab(key);
                              } else {
                                setActiveTab(key);
                              }
                            }}
                            activeKey={activeTab}
                            tabBarExtraContent={
                              <>
                                {set?.isAlreadyDesigned ? (
                                  isEditModeOn && activeTab === '3' ? (
                                    <Button
                                      onClick={() => {
                                        editQrInTemplate(template?.templateId);
                                      }}
                                    >
                                      Save changes
                                    </Button>
                                  ) : (
                                    <Space direction="horizontal">
                                      <Button
                                        onClick={async () => {
                                          if (
                                            isSaveQrClicked &&
                                            isTabSwitched
                                          ) {
                                            setShowWarningModal(true);
                                          } else {
                                            if (currentTab) {
                                              setCurrentTab(
                                                currentTab === 'front'
                                                  ? 'back'
                                                  : 'front'
                                              );
                                            }
                                            await wait(2000);

                                            if (frontNode && backNode) {
                                              editDesign(frontNode, backNode);
                                            }
                                          }
                                        }}
                                        loading={editingTemplate}
                                      >
                                        Save Edited
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          handleBulkGeneration();
                                        }}
                                      >
                                        Save all Edits
                                      </Button>
                                    </Space>
                                  )
                                ) : (
                                  <Button
                                    type="primary"
                                    onClick={async () => {
                                      //before submitting template switch the tab forcefully so that for both the tabs html will be generated.
                                      if (isSaveQrClicked && isTabSwitched) {
                                        setShowWarningModal(true);
                                      } else {
                                        if (currentTab) {
                                          setCurrentTab(
                                            currentTab === 'front'
                                              ? 'back'
                                              : 'front'
                                          );
                                        }
                                        await wait(2000);

                                        if (frontNode && backNode) {
                                          saveTemplate(frontNode, backNode);
                                        }
                                      }
                                    }}
                                    loading={savingTemplate}
                                    disabled={!frontNode || !backNode}
                                  >
                                    Save Template
                                  </Button>
                                )}
                              </>
                            }
                          >
                            <Tabs.TabPane tab="Create Template" key="1">
                              <DesignCenter
                                orderUniqueId={set?.orderUniqueId}
                                uploadInProgress={uploadInProgress}
                                setUploadInProgress={setUploadInProgress}
                                orgId={orgId}
                                setCardData={setCardData}
                                frontImageUploading={frontImageUploading}
                                backgroundImageUploading={
                                  backgroundImageUploading
                                }
                                handleBackgroundImageUpload={
                                  handleBackgroundImageUpload
                                }
                                frontCardImage={frontCardImage}
                                selectedColor={selectedColor}
                                setSelectedColor={setSelectedColor}
                                backCardImage={backCardImage}
                                onFileInput={onFileInput}
                                sameBackgroundColor={sameBackgroundColor}
                                setSameBackgroundColor={setSameBackgroundColor}
                                backgroundColorFront={backgroundColorFront}
                                setFrontCardImage={setFrontCardImage}
                                setBackCardImage={setBackCardImage}
                                setBackgroundColorBack={setBackgroundColorBack}
                                setBackgroundColorFront={
                                  setBackgroundColorFront
                                }
                                backgroundColorBack={backgroundColorBack}
                                saveTemplate={saveTemplate}
                                currentTab={currentTab}
                                setCurrentTab={setCurrentTab}
                                cardData={cardData}
                                hideField={hideField}
                                setHideField={setHideField}
                                newField={newField}
                                setNewField={setNewField}
                                front={front}
                                setFront={setFront}
                                back={back}
                                setBack={setBack}
                                fontData={fontData}
                                setFrontImageUploading={setFrontImageUploading}
                                setBackgroundImageUploading={
                                  setBackgroundImageUploading
                                }
                                frontNode={frontNode}
                                backNode={backNode}
                                isSaveQrClicked={isSaveQrClicked}
                                setIsTabSwitched={setIsTabSwitched}
                                activeUserTab={activeUserTab}
                                fetchingPreviousDesign={fetchingPreviousDesign}
                                isEditModeOn={isEditModeOn}
                                wait={wait}
                                set={set}
                                cardMaterial={cardMaterial}
                              />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Set QR code" key="2">
                              {!showQrPositionSetting && (
                                <QrSettings
                                  set={set}
                                  showQrPositionSetting={showQrPositionSetting}
                                  setShowQrPositionSetting={
                                    setShowQrPositionSetting
                                  }
                                  base64Str={base64Str}
                                  setBase64Str={setBase64Str}
                                  qrConfig={qrConfig}
                                  setQrConfig={setQrConfig}
                                  qrPixel={qrConfig?.qrPixelColor}
                                  backgroundColor={qrConfig?.qrBackgroundColor}
                                  qrSettingLoader={qrSettingLoader}
                                  setQrSettingLoader={setQrSettingLoader}
                                  roundedCorner={roundedCorner}
                                  setRoundedCorner={setRoundedCorner}
                                  showLogo={showLogo}
                                  setShowLogo={setShowLogo}
                                  isPreviousConfig={isPreviousConfig}
                                  qrX={qrX}
                                  setQrX={setQrX}
                                  qrY={qrY}
                                  setQrY={setQrY}
                                  qrRotation={qrRotation}
                                  setQrRotation={setQrRotation}
                                  setQrWidth={setQrWidth}
                                  setQrHeight={setQrHeight}
                                  qrBackground={getCardImage(selectedColor)}
                                />
                              )}
                              {
                              showQrPositionSetting ? (
                                <QrPosition
                                  type="designCentre"
                                  showQr={showQr}
                                  setShowQr={setShowQr}
                                  qrSide={qrSide}
                                  setQrSide={setQrSide}
                                  backgroundColorFront={backgroundColorFront}
                                  backgroundColorBack={backgroundColorBack}
                                  frontCardImage={frontCardImage}
                                  backCardImage={backCardImage}
                                  qrWidth={qrWidth}
                                  qrHeight={qrHeight}
                                  qrX={qrX}
                                  qrY={qrY}
                                  setQrX={setQrX}
                                  setQrY={setQrY}
                                  setQrWidth={setQrWidth}
                                  setQrHeight={setQrHeight}
                                  base64Str={base64Str}
                                  qrRotation={qrRotation}
                                  setQrRotation={setQrRotation}
                                  layout={layout}
                                  layout2={layout2}
                                  Slider={Slider}
                                  rotationMarks={rotationMarks}
                                  cardDesign={cardDesign}
                                  frontNode={frontNode}
                                  setFrontNode={setFrontNode}
                                  backNode={backNode}
                                  setBackNode={setBackNode}
                                  fontFace={fontFace}
                                  setIsSaveQrClicked={setIsSaveQrClicked}
                                  id="cdc-wrapper"
                                />
                              ) : null}
                            </Tabs.TabPane>
                            {set?.isAlreadyDesigned && (
                              <Tabs.TabPane
                                tab="Change QR code in template"
                                key="3"
                              >
                                {!showQrPositionSetting && (
                                  <QrSettings
                                    set={set}
                                    showQrPositionSetting={
                                      showQrPositionSetting
                                    }
                                    setShowQrPositionSetting={
                                      setShowQrPositionSetting
                                    }
                                    base64Str={base64Str}
                                    setBase64Str={setBase64Str}
                                    qrConfig={qrConfig}
                                    setQrConfig={setQrConfig}
                                    qrPixel={qrConfig?.qrPixelColor}
                                    backgroundColor={
                                      qrConfig?.qrBackgroundColor
                                    }
                                    qrSettingLoader={qrSettingLoader}
                                    setQrSettingLoader={setQrSettingLoader}
                                    roundedCorner={roundedCorner}
                                    setRoundedCorner={setRoundedCorner}
                                    showLogo={showLogo}
                                    setShowLogo={setShowLogo}
                                    isPreviousConfig={isPreviousConfig}
                                    qrX={qrX}
                                    setQrX={setQrX}
                                    qrY={qrY}
                                    setQrY={setQrY}
                                    qrRotation={qrRotation}
                                    setQrRotation={setQrRotation}
                                    setQrWidth={setQrWidth}
                                    setQrHeight={setQrHeight}
                                    qrBackground={getCardImage(selectedColor)}
                                  />
                                )}
                                {
                                showQrPositionSetting ? (
                                  <QrPosition
                                    type="designCentre"
                                    showQr={showQr}
                                    setShowQr={setShowQr}
                                    qrSide={qrSide}
                                    setQrSide={setQrSide}
                                    backgroundColorFront={backgroundColorFront}
                                    backgroundColorBack={backgroundColorBack}
                                    frontCardImage={frontCardImage}
                                    backCardImage={backCardImage}
                                    qrWidth={qrWidth}
                                    qrHeight={qrHeight}
                                    qrX={qrX}
                                    qrY={qrY}
                                    setQrX={setQrX}
                                    setQrY={setQrY}
                                    setQrWidth={setQrWidth}
                                    setQrHeight={setQrHeight}
                                    base64Str={base64Str}
                                    qrRotation={qrRotation}
                                    setQrRotation={setQrRotation}
                                    layout={layout}
                                    layout2={layout2}
                                    Slider={Slider}
                                    rotationMarks={rotationMarks}
                                    cardDesign={cardDesign}
                                    frontNode={frontNode}
                                    setFrontNode={setFrontNode}
                                    backNode={backNode}
                                    setBackNode={setBackNode}
                                    fontFace={fontFace}
                                    setIsSaveQrClicked={setIsSaveQrClicked}
                                    id="edit-wrapper"
                                  />
                                ) : null}
                              </Tabs.TabPane>
                            )}
                          </Tabs>
                        )}
                      </>
                    </TabPane>
                  );
                })
              : null}
          </Tabs>
        </Card>
        {!showQrPositionSetting &&
        designExperience === LEGACY_DESIGN_EXPERIENCE ? (
          <QrSettings
            set={set}
            showQrPositionSetting={showQrPositionSetting}
            setShowQrPositionSetting={setShowQrPositionSetting}
            base64Str={base64Str}
            setBase64Str={setBase64Str}
            qrConfig={qrConfig}
            setQrConfig={setQrConfig}
            qrPixel={qrConfig?.qrPixelColor}
            backgroundColor={qrConfig?.qrBackgroundColor}
            qrSettingLoader={qrSettingLoader}
            setQrSettingLoader={setQrSettingLoader}
            showLogo={showLogo}
            setShowLogo={setShowLogo}
            isPreviousConfig={isPreviousConfig}
            setQrX={setQrX}
            setQrY={setQrY}
            setQrRotation={setQrRotation}
            setQrWidth={setQrWidth}
            setQrHeight={setQrHeight}
            qrBackground={getCardImage(selectedColor)}
            frontCardImage={frontCardImage}
            backCardImage={backCardImage}
            hasSameBackSide={hasSameBackSide}
            hasSameFrontSide={hasSameFrontSide}
          />
        ) : (
          ''
        )}
        {
        showQrPositionSetting &&
        designExperience === LEGACY_DESIGN_EXPERIENCE ? (
          <QrPosition
            type={LEGACY_DESIGN_EXPERIENCE}
            showQr={showQr}
            setShowQr={setShowQr}
            qrSide={qrSide}
            setQrSide={setQrSide}
            backgroundColorFront={backgroundColorFront}
            backgroundColorBack={backgroundColorBack}
            frontCardImage={frontCardImage}
            backCardImage={backCardImage}
            setFrontCardImage={setFrontCardImage}
            setBackCardImage={setBackCardImage}
            qrWidth={qrWidth}
            qrHeight={qrHeight}
            qrX={qrX}
            qrY={qrY}
            setQrX={setQrX}
            setQrY={setQrY}
            setQrWidth={setQrWidth}
            setQrHeight={setQrHeight}
            base64Str={base64Str}
            qrRotation={qrRotation}
            setQrRotation={setQrRotation}
            layout={layout}
            layout2={layout2}
            Slider={Slider}
            rotationMarks={rotationMarks}
            setIsSaveQrClicked={setIsSaveQrClicked}
            setQrSettingLoader={setQrSettingLoader}
            cardDesign={cardDesign}
            setCardDesign={setCardDesign}
            hasSameBackSide={hasSameBackSide}
            hasSameFrontSide={hasSameFrontSide}
          />
        ) : null}
        {showQrPositionSetting && (
          <>
            <Divider />
            <Card size="small" title="Leave a Message">
              <Input.TextArea
                value={note}
                onChange={(e) => setNote(e?.target?.value)}
              ></Input.TextArea>
            </Card>
          </>
        )}
      </Card>
    </>
    </Spin>
  );
}

export default withRouter(AddDesign);
