import { EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Tabs } from 'antd';
import React, { useState } from 'react';
import './showCardPreview.css';

function ShowCardPreview({
  frontNode,
  backNode,
  frontCardImage,
  backCardImage
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Button icon={<EyeOutlined />} onClick={showModal} type="primary">
        Preview
      </Button>
      <Modal
        title="Card preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        wrapClassName="preview-modal"
      >
        <Tabs>
          <Tabs.TabPane tab="Front" key="front" className="card-preview">
            {frontNode ? (
              <div dangerouslySetInnerHTML={{ __html: frontNode }} />
            ) : (
              frontCardImage && <img src={frontCardImage} alt="Front Card" />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Back" key="back" className="card-preview">
            {backNode ? (
              <div dangerouslySetInnerHTML={{ __html: backNode }} />
            ) : (
              backCardImage && <img src={backCardImage} alt="Back Card" />
            )}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}

export default ShowCardPreview;
