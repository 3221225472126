import {
  Button,
  Card,
  Divider,
  Form,
  InputNumber,
  message,
  Radio,
  Space,
  Switch,
  Typography,
  Spin
} from 'antd';
import React, {useState} from 'react';
import { Rnd } from 'react-rnd';
import { LEGACY_DESIGN_EXPERIENCE } from '../../../../common/constant';
import { firebase } from '../../../../firebase';

const axios = require('axios');

function QrPosition({
  type,
  showQr,
  setShowQr,
  qrSide,
  setQrSide,
  backgroundColorFront,
  backgroundColorBack,
  frontCardImage,
  backCardImage,
  setFrontCardImage,
  setBackCardImage,
  qrWidth,
  qrHeight,
  qrX,
  qrY,
  setQrX,
  setQrY,
  setQrWidth,
  setQrHeight,
  base64Str,
  qrRotation,
  setQrRotation,
  layout,
  layout2,
  Slider,
  rotationMarks,
  frontNode,
  setFrontNode,
  backNode,
  setBackNode,
  fontFace,
  setIsSaveQrClicked,
  id,
  cardDesign,
  setCardDesign,
  hasSameBackSide,
  hasSameFrontSide
}) {
  const [isQrSet, setQr] = useState(false); 
  let backgroundColor;
  let backgroundImage;
  let cardNode;
  let isQrOnFront;
  let isQrOnBack;
  const [loading, setLoading] = useState(false);
  if (qrSide === 'FRONT') {
    backgroundColor = backgroundColorFront;
    backgroundImage = `url(${frontCardImage})`;
    cardNode = frontNode;
  } else {
    backgroundColor = backgroundColorBack;
    backgroundImage = `url(${backCardImage})`;
    cardNode = backNode;
  }

  const setQrOnCard = async (qrSide) => {
    try {
      setLoading(true);
  
      const token = await firebase.auth().currentUser.getIdToken(true);
      const cardDesignArray = Object.values(cardDesign);
      let isSameSide = qrSide === 'FRONT' ? hasSameFrontSide : hasSameBackSide;
      
      let imagesToCompose = cardDesignArray
        .map((design) => qrSide === 'FRONT' 
          ? design.frontBGImageUrl || frontCardImage 
          : design.backBGImageUrl || backCardImage)
        .filter(Boolean);
  
      let totalImages = imagesToCompose.length;
      let imagesGenerate = new Array(totalImages);
  
      if (isSameSide && imagesToCompose.length > 0) {
        imagesToCompose = [imagesToCompose[0]];
        totalImages = 1;
      }
  
      if (showQr && totalImages > 0) {
        const batchSize = 5;
        const retryLimit = 3;
  
        const sendBatchRequest = async (batch, batchIndex, attempt = 1) => {
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_SERVER_URL_V2}/design-template/preview/qr`,
              {
                images: batch,
                qrHeight: qrHeight * 5,
                qrWidth: qrWidth * 5,
                qrX: qrX * 5,
                qrY: qrY * 5,
                ...(base64Str && { qrLink: base64Str }),
                ...(qrRotation && { qrRotation }),
              },
              { headers: { authorization: `Bearer ${token}` } }
            );
  
            response.data.data.forEach((image, index) => {
              imagesGenerate[batchIndex * batchSize + index] = image;
            });
          } catch (error) {
            console.error(`Batch ${batchIndex + 1} failed (attempt ${attempt}):`, error);
  
            if (attempt < retryLimit) {
              const delay = Math.pow(2, attempt) * 1000;
              await new Promise((resolve) => setTimeout(resolve, delay));
              return sendBatchRequest(batch, batchIndex, attempt + 1);
            }
          }
        };
  
        const batchRequests = [];
  
        for (let i = 0; i < totalImages; i += batchSize) {
          const batch = imagesToCompose.slice(i, i + batchSize);
          batchRequests.push(sendBatchRequest(batch, i / batchSize));
        }
  
        await Promise.allSettled(batchRequests);
      }
  
      // Update cardDesign state
      setCardDesign((prevCardDesign) => {
        const updatedDesigns = { ...prevCardDesign };
  
        cardDesignArray.forEach((design, i) => {
          const empId = design.cardUser.employeeUniqueId;
          const bgKey = qrSide === 'FRONT' ? 'frontBGImageUrl' : 'backBGImageUrl';
          const bgWithoutQrKey = qrSide === 'FRONT' ? 'frontBGWithoutQrImageUrl' : 'backBGWithoutQrImageUrl';
  
          updatedDesigns[empId] = {
            ...(updatedDesigns[empId] || {}),
            [bgKey]: isSameSide ? imagesGenerate[0] || '' : imagesGenerate[i] || '',
            [bgWithoutQrKey]: design[bgKey] || '',
          };
        });
  
        return updatedDesigns;
      });
  
      message.success(`QR code is saved on ${qrSide} side of the card`);
    } catch (err) {
      console.error("Error setting QR on card:", err);
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleQrOnCard = () => {
    if (qrSide === 'FRONT') {
      if (type === LEGACY_DESIGN_EXPERIENCE) {
        setQrOnCard(qrSide);
      } else  {
        var frontHtml = document.getElementById(id);
        setFrontNode(
          `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${frontHtml?.innerHTML}</body></html>`
        );
        message.success('QR code is saved on front side of the card');
      }
    } else {
      if (type === LEGACY_DESIGN_EXPERIENCE) {
        setQrOnCard(qrSide);
      } else  {
        var backHtml = document.getElementById(id);
        setBackNode(
          `<html><head><style>${fontFace}</style></head><body style="margin: 0 !important">${backHtml?.innerHTML}</body></html>`
        );
        message.success('QR code is saved on back side of the card');
      }
    }
    setQr(true);
  };
  if (frontNode) {
    let frontParser = new DOMParser();
    const frontDoc = frontParser?.parseFromString(frontNode, 'text/html');
    isQrOnFront = frontDoc?.getElementById('qr');
  }
  if (backNode) {
    let backParser = new DOMParser();
    const backDoc = backParser?.parseFromString(backNode, 'text/html');
    isQrOnBack = backDoc?.getElementById('qr');
  }
  return (
    <Spin spinning={loading}>

    <>
      <Divider />
      <Card
        size="small"
        title="Position QR code"
        extra={
          <Space size="large">
            {isQrOnFront && isQrOnBack && (
              <Typography.Text type="danger">
                QR code is saved on both the sides
              </Typography.Text>
            )}
            {showQr && (
              <Radio.Group
                defaultValue={qrSide}
                buttonStyle="solid"
                size="small"
                onChange={(e) => setQrSide(e?.target?.value)}
              >
                <Radio.Button value="FRONT">Front</Radio.Button>
                <Radio.Button value="BACK">Back</Radio.Button>
              </Radio.Group>
            )}
            <Space size="small">
              Show QR code
              <Switch
                checked={showQr}
                onChange={(e) => {
                  if (
                    !e &&
                    document
                      ?.querySelector('.background')
                      ?.childNodes[0]?.querySelector('.content')?.childNodes[0]
                  ) {
                    document
                      .querySelector('.background')
                      .childNodes[0].querySelector('.content')
                      .childNodes[0].remove();
                  }
                  setShowQr(e);
                }}
              />
            </Space>
            {(
              <Button
                type="primary"
                onClick={() => {
                  handleQrOnCard();
                  setIsSaveQrClicked(true);
                }}
                disabled={
                  (qrSide === 'BACK' && isQrOnBack && showQr) ||
                  (qrSide === 'FRONT' && isQrOnFront && showQr) ||
                  (isQrSet)
                }
              >
                Save
              </Button>
            )}
          </Space>
        }
      >
        <Space>
          {type === LEGACY_DESIGN_EXPERIENCE ? (
            <div
              style={{
                overflow: 'hidden',
                width: 405,
                height: 255,
                background: backgroundColor,
                backgroundImage: backgroundImage,
                backgroundSize: 'cover'
              }}
            > {   
                <Rnd
                  style={{ position: 'relative' }}
                  lockAspectRatio={true}
                  size={{
                    width: qrWidth,
                    height: qrHeight
                  }}
                  position={{ x: qrX, y: qrY }}
                  onDrag={(e, d) => {
                    setQrX(d?.x);
                    setQrY(d?.y);
                  }}
                  bounds="parent"
                  onResizeStop={(e, direction, ref, delta, position) => {
                    setQrWidth(qrWidth + delta?.width);
                    setQrHeight(qrHeight + delta?.height);
                    setQrX(position?.x);
                    setQrY(position?.y);
                  }}
                >
                  {showQr && (
                    <img
                      src={base64Str ? ` ${base64Str}` : '/qr.png'}
                      style={{
                        width: '100%',
                        height: '100%',
                        transform: `rotate(${qrRotation}deg)`
                      }}
                      alt="QR"
                      id="qr"
                    />
                )}
              </Rnd>
              }
            </div>
          ) : (
            <div id={id} 
              style={{
                overflow: 'hidden',
                width: 405,
                height: 255,
                background: backgroundColor,
                backgroundImage: backgroundImage,
                backgroundSize: 'cover'
              }}>
              {
                !isQrSet && 
                <Rnd
                  className="content"
                  style={{ color: '#ffffff', zIndex: 100 }}
                  lockAspectRatio={true}
                  size={{
                    width: qrWidth,
                    height: qrHeight
                  }}
                  position={{ x: qrX, y: qrY }}
                  onDrag={(e, d) => {
                    setQrX(d?.x);
                    setQrY(d?.y);
                  }}
                  bounds="parent"
                  onResizeStop={(e, direction, ref, delta, position) => {
                    setQrWidth(qrWidth + delta?.width);
                    setQrHeight(qrHeight + delta?.height);
                    setQrX(position?.x);
                    setQrY(position?.y);
                  }}
                >
                  {showQr && (
                    <img
                      src={base64Str ? `${base64Str}` : '/qr.png'}
                      style={{
                        width: '100%',
                        height: '100%',
                        transform: `rotate(${qrRotation}deg)`
                      }}
                      alt="QR"
                      id="qr"
                    />
                )}
              </Rnd>
              }
              <div
                className="background"
                style={{ top: 0, left: 0, zIndex: -100 }}
              >
                {cardNode && (
                  <div dangerouslySetInnerHTML={{ __html: cardNode }} />
                )}
              </div>
            </div>
          )}
          <Space direction="vertical" style={{ minWidth: 500 }}>
            <Space>
              <Form.Item {...layout} label="QR- W">
                <InputNumber
                  value={qrWidth * 5}
                  min={0}
                  max={2022}
                  onChange={(v) => {
                    setQrWidth(v / 5);
                    setQrHeight(v / 5);
                  }}
                />
              </Form.Item>
              <Form.Item {...layout} label="QR- H">
                <InputNumber
                  value={qrHeight * 5}
                  min={0}
                  max={1275}
                  onChange={(v) => {
                    setQrHeight(v / 5);
                    setQrWidth(v / 5);
                  }}
                />
              </Form.Item>
            </Space>
            <Space>
              <Form.Item {...layout} label="QR- X">
                <InputNumber
                  value={qrX * 5}
                  min={0}
                  max={2022}
                  onChange={(v) => setQrX(v / 5)}
                />
              </Form.Item>
              <Form.Item {...layout} label="QR- Y">
                <InputNumber
                  value={qrY * 5}
                  min={0}
                  max={1275}
                  onChange={(v) => setQrY(v / 5)}
                />
              </Form.Item>
            </Space>

            <Form.Item {...layout2} label="QR- Rotation">
              <Slider
                value={qrRotation}
                onChange={(v) => setQrRotation(v)}
                max={270}
                marks={rotationMarks}
                step={null}
                defaultValue={37}
              />
            </Form.Item>
          </Space>
        </Space>
      </Card>
    </>
    </Spin>
  );
}

export default QrPosition;
